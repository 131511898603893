<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push({ name: 'Spheres' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($tc('words.organisationGroup', 2)) }}
      </span>
    </div>
    <b-overlay
      v-if="loading"
      id="overlay-background"
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
    />
    <b-container
      v-else
      fluid
    >
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
          >
            <h1>{{ currentUsergroup.display_name }}</h1>
            <SphereForm
              :sphere="currentUsergroup"
              @update="updateForm"
            />
          </b-col>
          <b-col
            cols="4"
            class="detail-card"
          >
            <UsergroupThumbnail
              :display-name="currentUsergroup.display_name"
              :usergroup="currentUsergroup"
              :thumbnail="currentUsergroup.thumbnail"
            />
            <div class="side-footer">
              <b-button
                variant="outline-secondary"
                style="float: right"
                @click="handleSubmit(updateSphere)"
              >
                {{ $t('buttons.saveChanges') }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>

      <b-row style="height: auto;">
        <b-col
          cols="11"
          class="users-table"
        >
          <h3 class="line-header">
            <img
              src="@/assets/icons/company.svg"
              alt="Icon company"
            > {{ capitalizeFirstLetter($tc('words.organisation', 2)) }}
          </h3>
          <h5 style="margin-left: 1em;">
            {{ $t('sphereDetail.organisations.label') }} {{ currentUsergroup.display_name }}
          </h5>
          <p style="margin: 0 0 0 1.2em; font-size: 1em;">
            {{ $t('sphereDetail.organisations.help') }}
          </p>
          <div
            class="row"
            style="margin: 0 0 1em 1em;"
          >
            <SearchOrganisations
              style="width: 40%; margin-right: 1rem;"
              :reset-after="false"
              :placeholder="$t('sphereDetail.organisations.placeholder')"
              :current-organisations="currentUsergroupChildren"
              @select="setSelectedOrganisation"
            />
            <b-button
              style="margin-top: 0.5em; width: fit-content;"
              variant="success"
              :disabled="!selectedOrganisation"
              @click="addOrganisationToSphere"
            >
              {{ $t('buttons.confirm') }}
            </b-button>
          </div>
          <SearchablePaginatedTable
            :name="'sphereChildrenTable'"
            :margin="'1rem 0 1rem 1rem'"
            :loading="loadingChildrenTable"
            :searchable="false"
            :fields="childrenFields"
            :rows="childrenItems"
            :count="currentUsergroupChildrenCount"
            :sort-function="GET_USERGROUP_CHILDREN"
            :selectable="false"
            @change-page="changeChildrenTablePage"
            @sorted="setChildrenTableSorted"
            @delete="openRemoveOrgModal"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SphereForm from '@/components/Forms/SphereForm.vue';
import SearchOrganisations from '@/components/Searchbars/SearchOrganisations.vue';
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';
import UsergroupThumbnail from '@/components/Usergroup/UsergroupThumbnail.vue';

import { mapState, mapMutations, mapActions } from 'vuex';
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'SphereDetail',

  components: {
    SphereForm,
    SearchOrganisations,
    SearchablePaginatedTable,
    UsergroupThumbnail,
    ValidationObserver
  },

  data() {
    return {
      loading: false,
      loadingChildrenTable: false,

      form: {},

      childrenTableSorted: {
        direction: null,
        field: null
      },
      removingOrg: false,
      orgToRemove: null,
      selectedOrganisation: null,
    };
  },

  computed: {
    ...mapState('usergroups', [
      'currentUsergroup',
      'currentUsergroupMembers',
      'currentUsergroupMembersCount',
      'currentUsergroupChildren',
      'currentUsergroupChildrenCount',
      'usergroupsRoles',
    ]),

    childrenFields() {
      return [
        {
          key: this.$t('sphereDetail.table.fields.name'),
          apiName: 'display_name',
          sortable: true,
          width: '',
          centered: false
        },
        {
          key: this.$t('sphereDetail.table.fields.id'),
          apiName: 'codename',
          sortable: true,
          width: '',
          centered: false
        },
        {
          key: this.$t('sphereDetail.table.fields.creationDate'),
          apiName: 'creation_date',
          sortable: true,
          width: '',
          centered: true
        },
        {
          key: this.$t('sphereDetail.table.fields.delete'),
          sortable: false,
          width: '',
          centered: true
        }
      ];
    },
    childrenItems() {
      return this.currentUsergroupChildren.map(el => {
        return {
          id: el.id,
          Nom: el.display_name,
          Identifiant: el.codename,
          'Date d\'inscription': new Date(el.creation_date).toLocaleString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric' }),
        };
      });
    },
  },

  created() {
    this.loading = true;
    Promise.all([
      this.GET_USERGROUP_DETAIL(this.$route.params.id),
      this.GET_USERGROUP_CHILDREN({
        id: this.$route.params.id,
        ...this.childrenTableSorted
      }),
    ])
      .then(() => {
        this.loading = false;
      })
      .catch((err) => {
        console.error(err);
        this.loading = false;
      });
    this.SET_TABLE({
      name: 'sphereMembersTable',
      table: {
        loading: false,
        currentPage: 1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUP_DETAIL',
      'GET_USERGROUP_CHILDREN',
      'GET_USERGROUPS_SPHERES_LIST',
      'UPDATE_USERGROUP',
    ]),

    ...mapActions('organisations', [
      'GET_ORGANISATIONS_LIST'
    ]),

    updateForm(e) {
      this.form = e;
    },

    setSelectedOrganisation(e) {
      this.selectedOrganisation = e;
    },

    addOrganisationToSphere() {
      this.loading = true;
      this.UPDATE_USERGROUP({
        id: this.currentUsergroup.id,
        data: {
          ...this.currentUsergroup,
          children: [...this.currentUsergroup.children, this.selectedOrganisation.usergroup.id]
        },
        usergroupType: 'sphere'
      }).then(() => {
        Promise.all([
          this.GET_USERGROUP_DETAIL(this.$route.params.id),
          this.GET_USERGROUP_CHILDREN({
            id: this.$route.params.id,
            ...this.childrenTableSorted
          })
        ]).then(() => {
          this.loading = false;
        });
      });
    },

    updateSphere() {
      this.loading = true;
      this.UPDATE_USERGROUP({
        id: this.currentUsergroup.id,
        data: this.form,
        usergroupType: 'sphere'
      }).then(() => {
        Promise.all([
          this.GET_USERGROUP_DETAIL(this.$route.params.id),
          this.GET_USERGROUPS_SPHERES_LIST({
            direction: null,
            field: null
          })
        ]).then(() => {
          this.loading = false;
        });
      });
    },

    changeChildrenTablePage() {
      this.loadingChildrenTable = true;
      this.GET_USERGROUP_CHILDREN({
        id: this.$route.params.id,
        ...this.childrenTableSorted
      })
        .then(() => {
          this.loadingChildrenTable = false;
        })
        .catch((err) => {
          console.error(err);
          this.loadingChildrenTable = false;
        });
    },

    setChildrenTableSorted(e) {
      this.childrenTableSorted = e;
    },

    openRemoveOrgModal(e) {
      this.orgToRemove = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.removeOrgFromSphere.title'),
        content: this.$t('modals.removeOrgFromSphere.content', {
          org: `${this.orgToRemove[this.$t('sphereDetail.table.fields.name')]}`,
          sphere: this.currentUsergroup.display_name
        }),
        trigger: this.removeOrgFromSphere
      });
    },

    removeOrgFromSphere() {
      const index = this.currentUsergroupChildren.findIndex(el => el.id === this.orgToRemove.id);
      const updatedChildren = this.currentUsergroupChildren.map(el => el.id);
      updatedChildren.splice(index, 1);
      const data = {
        ...this.currentUsergroup,
        children: updatedChildren
      };
      this.loading = true;
      this.UPDATE_USERGROUP({
        id: this.currentUsergroup.id,
        data: data,
        usergroupType: 'sphere'
      }).then(() => {
        Promise.all([
          this.GET_USERGROUP_DETAIL(this.$route.params.id),
          this.GET_USERGROUP_CHILDREN({
            id: this.$route.params.id,
            ...this.childrenTableSorted
          }),
          this.GET_ORGANISATIONS_LIST({
            direction: null,
            field: null
          })
        ])
          .then(() => {
            this.loading = false;
          });
      });
    },
  }
};
</script>

<style lang="less" scoped>

.container-fluid {
  margin: 1em;
  padding-bottom: 3em;
  height: fit-content;
  .row {
    height: fit-content;
  }
}

p {
  width: 100%;
  color: grey;
  font-style: italic;
  font-size: 0.7em;
  margin-bottom: 0.2em;
}

.b-overlay-wrap {
  table {
    width: calc(100% - 1rem);
  }
}

</style>
